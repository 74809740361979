import React from 'react';
import Input from '../input';
import Toast from '../toast';
import Button from '../button';
import Textarea from '../textarea';
import validation from './validation';
import { ContactFormWrapper } from './styles';
import { stiffy1 } from '../../styles/colors';
import { Form, Field } from 'react-final-form';
import toFinalFormInput from '../../utils/to-final-form-input';
import {
  Heading,
  Spacing,
  FormStyles,
  FieldStyles,
  CenteredContentWrapper
} from '../../styles';

const TextInput = toFinalFormInput(Input);
const LongTextInput = toFinalFormInput(Textarea);

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm = props => {
  const [status, setStatus] = React.useState(null);
  return (
    <>
      {status === 'success' && (
        <Toast
          title='Submitted'
          variant='success'
          onClose={() => setStatus(null)}
        >
          Your message has been received. We will try get back to you in 1
          business day.
        </Toast>
      )}
      {status === 'error' && (
        <Toast
          variant='warning'
          title='Failed to submit'
          onClose={() => setStatus(null)}
        >
          Unfortunately your message wasn't delivered. If this problem persists
          send as an <a href='mailto:learn@techrex.co.za'>email</a>.
        </Toast>
      )}
      <ContactFormWrapper {...props}>
        <CenteredContentWrapper>
          <Heading align='center' color={props.color || stiffy1}>
            Contact Us
          </Heading>
          <Spacing height={40} />
          <Form
            onSubmit={values => {
              fetch('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encode({ 'form-name': 'contact', ...values })
              })
                .then(() => setStatus('success'))
                .catch(error => {
                  console.error(error);
                  setStatus('error');
                });
            }}
            validate={validation}
          >
            {({ handleSubmit }) => (
              <form
                name='contact'
                data-netlify='true'
                onSubmit={handleSubmit}
                netlify-honeypot='country'
              >
                <input name='country' className='hidden' />
                <FieldStyles.Row>
                  <FieldStyles.InlineWrapper>
                    <Field
                      name='firstName'
                      component={TextInput}
                      placeholder='First name'
                    />
                  </FieldStyles.InlineWrapper>
                  <FieldStyles.InlineWrapper>
                    <Field
                      name='lastName'
                      component={TextInput}
                      placeholder='Last name'
                    />
                  </FieldStyles.InlineWrapper>
                </FieldStyles.Row>
                <FieldStyles.Row>
                  <FieldStyles.InlineWrapper>
                    <Field
                      name='email'
                      type='email'
                      component={TextInput}
                      placeholder='Email address'
                    />
                  </FieldStyles.InlineWrapper>
                  <FieldStyles.InlineWrapper>
                    <Field
                      type='tel'
                      name='phoneNumber'
                      component={TextInput}
                      placeholder='Phone number'
                    />
                  </FieldStyles.InlineWrapper>
                </FieldStyles.Row>
                <FieldStyles.Wrapper>
                  <Field
                    rows={8}
                    name='message'
                    component={LongTextInput}
                    placeholder='Type your message here...'
                  />
                </FieldStyles.Wrapper>
                <FormStyles.ActionsWrapper>
                  <Button type='submit'>Submit</Button>
                </FormStyles.ActionsWrapper>
              </form>
            )}
          </Form>
        </CenteredContentWrapper>
      </ContactFormWrapper>
    </>
  );
};

export default ContactForm;
