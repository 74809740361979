import { console0, dinosaur0, sunshine0, stiffy0 } from '../../styles/colors';

export const variants = ['success', 'warning', 'default'];

export const getBackgroundColorByVariant = variant => {
  switch (variant) {
    case 'success':
      return dinosaur0;
    case 'warning':
      return sunshine0;
    default:
      return console0;
  }
};

export const getHeadingColorByVariant = variant => {
  switch (variant) {
    case 'success':
    case 'warning':
      return console0;
    default:
      return stiffy0;
  }
};

export const getHeadingTitleByVariant = variant => {
  switch (variant) {
    case 'success':
      return 'success';
    case 'warning':
      return 'unsuccessful';
    default:
      return 'information';
  }
};
