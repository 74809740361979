import { keyframes } from 'styled-components';

export const slideInTop = keyframes`
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  90% {
    transform: translateY(0);
    opacity: 1.0;
  }
  95% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const slideInRight = keyframes`
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  90% {
    transform: translateX(0);
    opacity: 1.0;
  }
  95% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;
