import {
  isEmail,
  required,
  minLength,
  validateAll
} from '../../utils/validation';

export default ({ firstName, lastName, email, phoneNumber, message }) => {
  const errors = {};
  const validLength = minLength(3);

  let error = validateAll(required, validLength)(firstName);
  if (error) {
    errors.firstName = error;
  }

  error = validateAll(required, validLength)(lastName);
  if (error) {
    errors.lastName = error;
  }

  error = validateAll(required, isEmail)(email);
  if (error) {
    errors.email = error;
  }

  error = validateAll(required, minLength(10))(phoneNumber);
  if (error) {
    errors.phoneNumber = error;
  }

  error = validateAll(required, validLength)(message);
  if (error) {
    errors.message = error;
  }

  console.log('errors', errors);
  return errors;
};
