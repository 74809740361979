import styled, { css } from 'styled-components';
import { Close, Check, Info } from 'styled-icons/material';
import { tablet } from '../../styles/breakpoints';
import { stiffy0 } from '../../styles/colors';

// variants
import {
  getBackgroundColorByVariant,
  getHeadingColorByVariant
} from './helpers';
// animations
import { slideInTop, slideInRight } from './animations';

export const Container = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background-color: ${({ variant }) => getBackgroundColorByVariant(variant)};
  padding: 13px;
  width: 100%;
  box-shadow: 8px 13px 18px rgba(7, 0, 37, 0.05);
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  animation: ${slideInTop} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  cursor: pointer;

  @media (min-width: ${tablet}px) {
    position: fixed;
    top: 64px;
    left: auto;
    right: 16px;
    animation: ${slideInRight} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    width: 300px;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  color: ${({ variant }) => getHeadingColorByVariant(variant)};
  margin-bottom: 6px;
`;

const iconStyles = css`
  fill: currentColor;
  font-size: unset;
  height: 13px;
  margin-right: 8px;
`;

export const CloseIcon = styled(Close)`
  ${iconStyles};
`;

export const CheckIcon = styled(Check)`
  ${iconStyles};
`;

export const InfoIcon = styled(Info)`
  ${iconStyles};
`;

export const Content = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${({ variant }) => getHeadingColorByVariant(variant)};
`;
