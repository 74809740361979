import styled, { css } from 'styled-components/macro';
import { console0 } from '../../styles/colors';
import { Section } from '../../styles';

export const ContactFormWrapper = styled(Section)`
  background: ${({ background }) => background || console0};
  ${({ noTopPadding }) =>
    noTopPadding &&
    css`
      padding-top: 0;
    `}
`;
