import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Heading,
  Content,
  CloseIcon,
  CheckIcon,
  InfoIcon
} from './styles';

import { variants, getHeadingTitleByVariant } from './helpers';

const Toast = ({ className, children, onClose, variant, title }) => {
  const [visible, setVisible] = useState(true);

  return (
    <Container
      className={className}
      visible={visible}
      variant={variant}
      onClick={() => {
        setVisible(false);
        if (onClose) {
          onClose();
        }
      }}
    >
      <Heading variant={variant}>
        {variant === 'default' && <InfoIcon />}
        {variant === 'success' && <CheckIcon />}
        {variant === 'warning' && <CloseIcon />}
        {title || getHeadingTitleByVariant(variant)}
      </Heading>
      {children && <Content variant={variant}>{children}</Content>}
    </Container>
  );
};

Toast.defaultProps = {
  variant: 'default'
};

Toast.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(variants),
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default Toast;
